import React from "react"
import ReactWOW from "react-wow"

import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image"

const Banner = () => {
  return (
    <div className="it-services-banner one">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="main-banner-content">
              <ReactWOW delay=".1s" animation="fadeInLeft">
                <h1>
                Less Stress. <br/> More fulfilment.
                </h1>
              </ReactWOW>

              <ReactWOW delay=".1s" animation="fadeInLeft">
                <h4>
                Our Therapies, Courses, Workshops, and Retreats 
                will elevate your Energy, Mental & Emotional Wellbeing.
                </h4>
              </ReactWOW>

              <ReactWOW delay=".1s" animation="fadeInRight">
                <div className="btn-box">
                  <AnchorLink to="#services" className="default-btn" title="Get Started">
                    <i className="flaticon-right"></i>
                    Get Started <span></span>
                  </AnchorLink>
                </div>
              </ReactWOW>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <ReactWOW delay=".1s" animation="fadeInUp">
              <div className="main-banner-image">
                <StaticImage src="../../assets/images/banner-img5.png" width={585} height={648} placeholder="blurred" alt="Connection Goal Banner" />
              </div>

              <div className="shape-img4">
                <ul className="hearts flying">
                  <li className="heart"></li>
                  <li className="heart"></li>
                  <li className="heart"></li>
                  <li className="heart"></li>
                  <li className="heart"></li>
                  <li className="heart"></li>
                  <li className="heart"></li>
                  <li className="heart"></li>
                  <li className="heart"></li>
                  <li className="heart"></li>
                  <li className="heart"></li>
                  <li className="heart"></li>
                </ul>
              </div>
            </ReactWOW>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
