import React from "react"
import Layout from "../components/App/Layout"
import Banner from "../components/Home/Banner" 
import OurServices from "../components/Home/OurServices"
import OurSolutions from "../components/Home/OurSolutions"
import OurLocations from "../components/Home/OurLocations"
import ExploreKids from "../components/Kids/ExploreKids"
import ExploreEvents from "../components/Events/ExploreEvents"
import Testimonials from '../components/Common/Testimonials' 

const Index = () => {
  return (
    <Layout page="Home"> 
      <Banner />
      <OurServices />
      <OurSolutions />
      <OurLocations />
      <ExploreKids />
      <ExploreEvents />
      <Testimonials />
    </Layout>
  )
}

export default Index