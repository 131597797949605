import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import VideoResponsivePlayer from '../App/VideoResponsivePlayer'
import starIcon from "../../assets/images/star-icon.png"

const ExploreEvents = () => {

    const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        videosBaseUrl
      }
    }
  }
`)
    
    return (
        <React.Fragment>
            <div className="explore-learning-area ptb-100">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-lg-6 col-md-12">
                            <div className="explore-learning-content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="team" />
                                    Social
                                </span>
                                <h2>All diversity is welcome!</h2>
                                <p>If you are single, couple, parent (also pet parent!), single mum, LGBT community, immigrant, you belong here! We’re a virtual and in-person social-enthusiast group hosting live social events on a range of speed topics such as dating, friending, and mumming.<br/>
                                You can join us in person if possible or on one of our live streams. Look out for our virtual happy hours and other networking events.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="explore-learning-video">
                                <VideoResponsivePlayer 
                                    url={data.site.siteMetadata.videosBaseUrl + '/diversity.mp4'}
                                    controls={false}
                                    playing={true}
                                    loop={true}
                                    muted={true}
                                    width='100%'
                                    height='100%'
                                />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ExploreEvents