import React from "react"
import starIcon from "../../assets/images/star-icon.png"
import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const OurSolutions = () => {
  return (
    <section className="about-area bg-f1f8fb pb-100 pt-100">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-img">
              <StaticImage
                src="../../assets/images/home/treatments.jpg"
                placeholder="blurred"
                width={650}
                height={810}
                alt="Our Solutions"
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                <span className="sub-title">
                  <img src={starIcon} alt="icon" /> Wellness
                </span>

                <h2>
                  Connect with your body, mind, and spirit. Improve the quality
                  of your life.
                </h2>
                <p>
                  Find new strength for everyday challenges, significant
                  transitions, and trauma recovery.
                  <br />
                  Embody the Self, heal and integrate injured parts of you.
                  Cultivate a deeper, more compassionate understanding of
                  yourself.
                  <br />
                  Rewire your brain and the nervous system to release negative
                  patterns and embrace a more positive future.
                  <br />
                  Find the service that’s right for you.
                  <br />
                  Rollover to learn more about each wellness category
                </p>
                <ul className="about-list mb-0">
                  <li>
                    <AnchorLink to="/therapies#reiki">
                      <i className="flaticon-tick"></i>
                      Reiki session
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to="/therapies#eft">
                      <i className="flaticon-tick"></i>
                      EFT
                    </AnchorLink>
                  </li>

                  <li>
                    <AnchorLink to="/therapies#fengshui">
                      <i className="flaticon-tick"></i>
                      Feng Shui
                    </AnchorLink>
                  </li>
                </ul>
                <div className="col-lg-12 col-md-12">
                  <div className="text-center"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurSolutions
