import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import { StaticImage } from "gatsby-plugin-image"

const OurServices = () => {
    return (
        <section id="services" className="blog-area pt-70 pb-70">
            <div className="container">
            <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="Our Services" /> 
                        Our Services
                    </span>
                    <h2>So, you think you are special? You are!</h2>
                    <p>People with different tribes, races, religions, and nationalities can come together and accomplish something extraordinary.<br/>
                    Become an author of your life, and not just ride as it unfolds.<br/>
                    Create a life that you love.<br/>
                    Holistic treatments, Courses, Workshops, retreats & much more!
                    </p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post bg-fffbf5">
                            <div className="post-image">
                                <Link to="/wellness" state={{page: 'wellness'}}>
                                    <StaticImage src="../../assets/images/home/coporate.jpg" width={400} height={300} placeholder="blurred" alt="Corporate" />
                                </Link>
                            </div>

                            <div className="post-content">
                                <h3>
                                
                                        CORPORATE

                                </h3>
                                <p>Helping your employees navigate their parenting journey.<br/>
                                For all families.<br/>
                                For modern parents.<br/></p>
                                <br/>
                            </div>
                        </div>
                    </div> 

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post bg-fffbf5">
                            <div className="post-image">
                               
                                    <StaticImage src="../../assets/images/home/family.jpg" width={400} height={300} placeholder="blurred" alt="Family" />
                                
                            </div>

                            <div className="post-content">
                                <h3>
                                    
                                    COURSES
                                    
                                </h3>
                                <p>Our goal is to  teach, inspire and develop a "chain of good" culture with diversity, connection, purpose, and love.<br/>
                                We love to teach and inspire!<br/>
                                Tell me, and I forget, teach me, and I may remember, involve me, and I learn.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div className="single-blog-post bg-fffbf5">
                            <div className="post-image">
                                <Link to="/therapies" state={{page: 'therapies'}}>
                                    <StaticImage src="../../assets/images/home/therapies.jpg" width={400} height={300} placeholder="blurred" alt="Diversity" />
                                </Link>
                            </div>

                            <div className="post-content">
                                <h3>
                                    <Link to="/therapies" state={{page: 'therapies'}}>
                                        THERAPIES
                                    </Link>
                                </h3>
                                <p>Our goal is to  teach, inspire and develop a "chain of good" culture with diversity, connection, purpose, and love.<br/>
                                We love to teach and inspire!<br/>
                                Tell me, and I forget, teach me, and I may remember, involve me, and I learn.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurServices;