import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from 'styled-components'

const OurLocations = () => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(
          relativePath: { eq: "funfacts/funfacts-bg.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 95, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="section"
      fluid={imageData}
      backgroundColor={`#040e18`}
    >
      <section className="funfacts-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-4 col-6">
              <div className="single-funfacts-item">
                <div className="icon">
                  <StaticImage
                    src="../../assets/images/home/online.png"
                    width={100}
                    height={100}
                    placeholder="blurred"
                    alt="Online"
                  />
                </div>
                <h2>Online</h2>
              </div>
            </div>

            <div className="col-lg-4 col-sm-4 col-6">
              <div className="single-funfacts-item">
                <div className="icon">
                  <StaticImage
                    src="../../assets/images/home/in-home.png"
                    width={100}
                    height={100}
                    placeholder="blurred"
                    alt="In-home"
                  />
                </div>
                <h2>In-home</h2>
              </div>
            </div>

            <div className="col-lg-4 col-sm-4 col-6">
              <div className="single-funfacts-item">
                <div className="icon">
                  <StaticImage
                    src="../../assets/images/home/in-company.png"
                    width={100}
                    height={100}
                    placeholder="blurred"
                    alt="In-company"
                  />
                </div>
                <h2>In-company</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </BackgroundImage>
  )
}

const StyledOurLocations = styled(OurLocations)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`
export default StyledOurLocations

