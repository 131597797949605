import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'
import VideoResponsivePlayer from '../App/VideoResponsivePlayer'
import starIcon from "../../assets/images/star-icon.png"

const ExploreKids = () => {
    
    const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        videosBaseUrl
      }
    }
  }
`)

    return (
        <React.Fragment>
            <div className="explore-learning-area ptb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="explore-learning-content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="team" />
                                    Our Kids
                                </span>
                                <h2>Classes & activities for kids!</h2>
                                <p>Emotional intelligence can be exercised, just like intellectual intelligence, through meaningful children’s activities and experiences.</p>
                                <br/>
                                
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="explore-learning-video">
                                <VideoResponsivePlayer 
                                    url={data.site.siteMetadata.videosBaseUrl + '/kids_classes.mp4'}
                                    light={data.site.siteMetadata.videosBaseUrl + '/kids_classes_snap.jpg'}
                                    controls={true}
                                    playing={true}
                                    loop={false}
                                    muted={false}
                                    width='100%'
                                    height='100%'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ExploreKids